<template>
  <v-container>
    <v-card elevation="8" color="secondary" align="center" dark class="pa-4">
      <v-avatar class="mb-4" color="success" size="64">
        <h1>{{ userInitials }}</h1>
      </v-avatar>
      <v-card-title align="center" class="justify-center nowordbreak" justify="middle">
        {{ userdata.fullname }}<br />({{ role }})</v-card-title
      >
    </v-card>

    <v-list flat>
        <v-list-item v-for="(item, index) in availableRoutes" :key="index" @click="setDrawer(false)">
          <router-link :to="item.path ? item.path : ''">{{
            item.name
          }}</router-link>
        </v-list-item>
        
      
      
        <v-list-group no-action v-if="role==='admin'||role==='commissione'||role==='segreteria'">
        <template v-slot:activator>
        <v-list-item-content v-model="group">
            <v-list-item-title class="likea"
              >Impostazioni</v-list-item-title
            >
        </v-list-item-content>
        </template>
        <v-list-item v-for="(item, index) in settingsRoutes" :key="index" class="lowpadding">
          <router-link :to="item.path ? item.path : ''">{{
            item.name
          }}</router-link>
        </v-list-item></v-list-group>
        <v-list-item @click="logout()" class="likea"> Logout </v-list-item>
    </v-list>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data: () => {
    return {
      group: null,
    };
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    ...mapActions({
      logout: "logout",
    }),
  },
  computed: {
    ...mapState(["drawer", "userInitials", "userdata", "role"]),
    availableRoutes() {
      let retval = [];
      for (const route of this.$router.options.routes) {
        if (
          route.meta.roles.includes(this.role) &&
          route.meta.parent !== "settings" && route.meta.leftMenu
        ) {
          retval.push(route);
        }
      }
      return retval;
    },
    settingsRoutes() {
      let retval = [];
      for (const route of this.$router.options.routes) {
        if (
          route.meta.roles.includes(this.role) &&
          route.meta.parent === "settings" && route.meta.leftMenu
        ) {
          retval.push(route);
        }
      }
      return retval;
    },
  },
  watch: {
    group() {
      this.setDrawer(false);
    },
  },
  created() {},
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.theme--light.v-list-item.likea {
  color: #003366;
}
.v-list-item__title {
  color: #003366;
}
.v-list-group__items > .v-list-item.lowpadding {
  padding-left: 30px;
}
.v-card__title.nowordbreak {
  word-break: normal;
}
</style>