<template>
  <v-app>
    <v-container v-if="this.$store.state.mainloading">
      <div class="text-center ma-4">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-container>

    <v-container v-else-if="is403">
      <v-app-bar app height="100">
        <!-- -->
        <v-row class="fill-height">
          <v-col cols="1">
          </v-col>
          <v-col cols="3" self-align="center"><v-img contain src="@/assets/unimi-DI.png"></v-img></v-col>
          <v-col cols="2" align="center"><span class="text-h4 primaryColor">Gestione tirocini</span></v-col>
          <v-spacer></v-spacer>
          <v-col cols="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"
                  @click="$store.dispatch('logout')"><v-icon>mdi-exit-run</v-icon></v-btn>
              </template>
              <span>Logout</span>
            </v-tooltip></v-col>
        </v-row>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <v-card elevation="8" class="pa-8">
            <v-card-title>Accesso non autorizzato</v-card-title>
          </v-card>

        </v-container>
      </v-main>

      <v-footer app>
        <!-- -->
      </v-footer>
    </v-container>
    <v-container v-else>
      <v-navigation-drawer app v-model="$store.state.drawer" temporary>
        <!-- -->
        <navigation-menu></navigation-menu>
      </v-navigation-drawer>

      <v-app-bar app height="100">
        <!-- -->
        <v-row class="fill-height">
          <v-col cols="1">
            <v-app-bar-nav-icon @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
          </v-col>
          <v-col cols="3" self-align="center"><v-img contain src="@/assets/unimi-DI.png"></v-img></v-col>
          <v-col cols="2" align="center"><span class="text-h4 primaryColor">Gestione tirocini</span></v-col>
          <v-spacer></v-spacer>
          <v-col cols="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"
                  @click="$store.dispatch('logout')"><v-icon>mdi-exit-run</v-icon></v-btn>
              </template>
              <span>Logout</span>
            </v-tooltip></v-col>
        </v-row>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <router-view></router-view>
        </v-container>
      </v-main>

      <v-footer app>
        <!-- -->
      </v-footer>
    </v-container>
    <v-overlay :value="cookieLawOpened" absolute>
    </v-overlay>

    <cookie-law theme="mytheme" buttonText="Accetto" @accept="cookieAccepted">
      <v-container slot-scope="props">
        <v-row>
          <v-col cols="8">
            Questo sito utilizza cookie ed altri strumenti tecnici per fornire la migliore esperienza d'uso. Proseguendo
            la
            navigazione se ne accetta l'utilizzo.<br />
            Per la policy completa cliccare <!--v-btn color="primary" @click="cookieDialog = true">qui</v-btn--><a @click="cookieDialog = true">qui</a>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" class="text-right">
            <v-btn color="primary" @click="props.accept"><span>Accetto</span></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </cookie-law>
    <v-dialog v-model="cookieDialog" content-class="noscroll" fullscreen scrollable>
      <v-card elevation="8">
        <v-toolbar dark color="primary">
          <v-row>
            <v-col>
              <h2>Privacy policy</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="1" class="text-right">
              <v-btn icon dark @click="cookieDialog = false"><v-icon>mdi-close-thick</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-card-text>
          <h4>Premessa</h4>
          <p>
            Ai sensi dell'art. 13 del Regolamento (UE) 2016/679 (Regolamento Generale sulla Protezione dei Dati o RGPD),
            l'Università degli Studi di Milano (nel seguito indicata anche come “Ateneo”) nella persona del Rettore pro
            tempore, informa gli utenti in merito all'utilizzo dei dati personali che li riguardano da parte del sito web
            https://tirocini.di.unimi.it (di seguito anche “sito”).
            La presente informativa è limitata al solo sito https://tirocini.di.unimi.it e non ha valore per i siti
            esterni anche se accessibili a partire da link presenti su https://tirocini.di.unimi.it.
            Resta ferma l'osservanza da parte dell'Università degli Studi di Milano della vigente normativa in materia di
            trasparenza e di pubblicazione obbligatoria di dati e documenti.
          </p>
          <h4>1. Titolare del trattamento e Responsabile per la protezione dei dati (RPD)</h4>
          <p>Titolare del trattamento dei dati è l'Università degli Studi di Milano, nella persona del Rettore pro
            tempore, via Festa del Perdono n. 7, 20122 Milano, e-mail infoprivacy@unimi.it. Ai sensi degli artt. 37 e
            seguenti del Regolamento UE 2016/679, l'Ateneo ha nominato il Responsabile per la protezione dei dati (RPD)
            contattabile all'indirizzo e-mail dpo@unimi.it.
            Per informazioni o approfondimenti sull'utilizzo dei dati personali trattati durante la navigazione e
            l'interazione con il sito, contattare il Direttore del Dipartimento di Informatica “Giovanni degli Antoni”
            all'indirizzo e-mail direttore@di.unimi.it.</p>
          <h4>2. Finalità e base giuridica del trattamento</h4>
          <p>
            Vengono elencati, di seguito, i dati che verranno trattati:</p>
          <ul>
            <li>dati di navigazione. A titolo d'esempio: indirizzo IP, tipo di browser e parametri del dispositivo usato
              per connettersi al sito, nome dell'internet service provider (ISP), data ed orario di visita, pagina web di
              provenienza del visitatore (referral) e di uscita;</li>

            <li>dati forniti volontariamente dall'utente ai fini dell'autenticazione ad aree riservate del sito (username
              e password) e ai fini del servizio di richiesta tirocini.</li>
          </ul>

          <p>Le suddette informazioni sono trattate in forma automatizzata e raccolte anche in forma aggregata al fine di
            verificare il corretto funzionamento del sito e per motivi di sicurezza. Tali informazioni saranno trattate in
            base al legittimo interesse del titolare.
            Ai fini di sicurezza (filtri antispam, firewall, rilevazione virus), i dati registrati automaticamente possono
            eventualmente comprendere dati personali come l'indirizzo IP, che potrebbe essere utilizzato, in conformità
            alla legge vigente, al fine di bloccare tentativi di danneggiamento al sito o di recare danno ad altri utenti,
            o comunque attività dannose o costituenti reato. In ogni caso tali dati non verranno mai utilizzati a fini di
            profilazione dell'utenza del sito ma solo a fini di tutela del sito e dei suoi utenti.
            Le basi giuridiche del trattamento sono, quindi: l'ottemperanza a obblighi di legge e contrattuali,
            l'adempimento di specifiche richieste dell'interessato prima della conclusione del contratto e il trattamento
            dei dati connesso alla gestione di eventuali reclami o contenziosi e per la prevenzione e repressione di frodi
            e di qualsiasi attività illecita, nonché l'adempimento di un compito di interesse pubblico.
          </p>
          <h4>3. Tipi di dati trattati</h4>
          <p>
            I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:

          <ol>
            <li>Accesso alla procedura di attivazione e gestione di un tirocinio, tramite l'autenticazione al sistema di
              convalida dell'Ateneo.</li>
            <li>Comunicazioni e notifiche tra le figure coinvolte nel processo</li>
            <li>Valutazione degli adempimenti prescritti dalla procedura dei tirocini</li>
          </ol>
          I sistemi informatici e le procedure applicative preposte al funzionamento del sito acquisiscono, nel corso del
          loro normale esercizio, alcuni dati la cui trasmissione è implicita nell'uso dei protocolli di comunicazione
          sulla Internet.
          Tali informazioni vengono utilizzate per ricavare informazioni statistiche anonime sull'uso del portale e per
          controllarne il corretto funzionamento e non sono associate a utenti identificati; tuttavia per loro natura e
          mediante associazioni con dati trattati da terzi, potrebbero consentire l'identificazione degli interessati.
          Rientra in questa categoria, ad esempio, l'indirizzo IP del sistema utilizzato per collegarsi al portale oppure
          l'indirizzo email.
          Questi dati sono conservati off-line esclusivamente per accertamenti di responsabilità in caso di reati
          informatici e consultabili solo su richiesta dell'autorità giudiziaria. Per l'utilizzo di servizi on-line che
          prevedono l'autenticazione al fine di consentire l'accesso ad aree riservate del sito, sono utilizzati dati
          personali forniti liberamente dagli utenti. Per ulteriori informazioni, consulta il paragrafo successivo.
          </p>
          <h4>4. Facoltatività del conferimento dei dati</h4>
          <p>
            I dati di autenticazione (username e password) sono conferiti volontariamente, ma il loro mancato conferimento
            comporta l'impossibilità di accedere alle aree riservate del portale.
            I dati di login consentono l'autorizzazione all'uso del servizio.
            Il soggetto che effettua il login dà liberamente il consenso al trattamento dei dati, consapevole che in
            assenza di tale consenso non sarà possibile usufruire del servizio richiesto.
            I trattamenti effettuati prima della revoca del consenso da parte dell'interessato resteranno comunque validi.
          </p>
          <h4>5. Modalità del trattamento</h4>
          <p>
            La raccolta dei dati avviene nel rispetto dei principi di pertinenza, completezza e non eccedenza in relazione
            ai fini per i quali sono trattati.
            I dati personali conferiti sono trattati in osservanza dei principi di liceità, correttezza e trasparenza,
            previsti dall'articolo 5 RGPD, anche con l'ausilio di strumenti informatici e telematici atti a memorizzare e
            gestire i dati stessi, e comunque in modo tale da garantirne la sicurezza e tutelare la massima riservatezza
            dell'interessato.
            I dati potrebbero essere oggetto di trattamento in forma anonima per lo svolgimento di attività statistiche
            finalizzate al miglioramento dei servizi offerti.
          </p>
          <h4>6. Utilizzo di cookie</h4>
          <p>
            I cookie sono file di testo che vengono depositati sui computer degli utenti web per consentire l'esplorazione
            sicura ed efficiente del sito e monitorarne l'utilizzo.
            Il sito https://tirocini.di.unimi.it non utilizza cookie tecnici, cookie di monitoraggio e cookie di terze
            parti.
            Non vengono utilizzati cookie con esplicite finalità di profilazione o per finalità differenti da quelle qui
            dichiarate.
            Questo sito non usa cookie, ma utilizza un token di sicurezza per gestire l'autenticazione dell'utente
            necessaria all'accesso alle eventuali aree riservate, se presenti, e validare le operazioni effettuate durante
            la sessione.
            Il token di sicurezza (JWT) non viene memorizzato in modo persistente sul computer dell'utente e ha una durata
            di 24 ore dall'ultima operazione effettuata sul sito.
          </p>
          <h4>7. Categorie di soggetti autorizzati al trattamento e ai quali i dati possono essere comunicati</h4>
          <p>
            I dati personali degli utenti saranno conosciuti e trattati, nel rispetto della vigente normativa in materia,
            dal personale del Dipartimento (individuati come Autorizzati al trattamento) coinvolto nella manutenzione del
            sito.
            I dati potranno essere comunicati:
          <ol>
            <li>alle strutture dell'Ateneo che ne facciano richiesta, per le finalità istituzionali dell'Ateneo o in
              osservanza di obblighi legislativi;</li>
            <li>a soggetti pubblici non economici o consorzi partecipati dall'Ateneo (ad es. MIUR) quando la comunicazione
              è necessaria per lo svolgimento di funzioni istituzionali dell'ente richiedente;</li>
            <li>ad alcuni soggetti esterni, individuati come Responsabili del trattamento ex art. 28 RGPD;</li>
          </ol>
          È fatta salva, in ogni caso, la comunicazione o diffusione di dati richiesti, in conformità alla legge,
          dall'Autorità di Pubblica Sicurezza, dall'Autorità Giudiziaria o da altri soggetti pubblici per finalità di
          difesa, sicurezza dello Stato ed accertamento dei reati, nonché la comunicazione all'Autorità Giudiziaria in
          ottemperanza ad obblighi di legge, laddove si ravvisino ipotesi di reato.
          Non saranno, infine, trasferiti dati personali verso Paesi terzi od organizzazioni internazionali a meno che ciò
          non sia strettamente connesso a richieste specifiche provenienti dall'utente o a necessità legate alla
          finalizzazione dell'intervento, per le quali si acquisirà apposito consenso.
          </p>
          <h4>8. Conservazione dei dati</h4>
          <p>
            In relazione alle diverse finalità e agli scopi per i quali sono stati raccolti, i dati saranno conservati per
            il tempo previsto dalla normativa di riferimento ovvero per quello strettamente necessario al perseguimento
            delle finalità. Nello specifico:
          <ul>
            <li>i dati di navigazione verranno conservati per un massimo di 5 anni;</li>
            <li>i dati forniti volontariamente dagli utenti verranno conservati per la durata dell'afferenza al
              Dipartimento dell'utente o fino alla richiesta di cancellazione che lo stesso può richiedere.</li>
          </ul>
          </p>
          <h4>9. Diritti dell'Interessato</h4>
          <p>
            Gli interessati hanno il diritto di ottenere dal Garante, nei casi previsti, l'accesso ai propri dati
            personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che li riguarda o di
            opporsi al trattamento (artt. 15 e ss. del Regolamento).
            L'istanza è presentata contattando il Responsabile della protezione dei dati (Responsabile della Protezione
            dei Dati, via Festa del Perdono, 7, 20122 Milano - e-mail: dpo@pec.unimi.it).
          </p>
          <h4>10. Diritto di reclamo</h4>
          <p>Gli interessati che ritengono che il trattamento dei dati personali a loro riferiti effettuato attraverso
            questo sito avvenga in violazione di quanto previsto dal Regolamento, hanno il diritto di proporre reclamo al
            Garante, come previsto dall'art. 77 del Regolamento stesso, o di adire le opportune sedi giudiziarie (art. 79
            del Regolamento).</p>
          <h4>11. Modifiche alle Informazioni</h4>
          <p>
            Le presenti informazioni potrebbero subire delle modifiche nel tempo. Si consiglia, pertanto, di verificare,
            nella sezione Privacy del sito web https://tirocini.di.unimi.it, che la versione a cui ci si riferisce sia la
            più aggiornata.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="2">
              <v-btn color="primary" @click="cookieDialog = false">Chiudi</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const URL = require("url");
import { mapState, mapMutations, mapActions } from "vuex";
import CookieLaw from 'vue-cookie-law'

import NavigationMenu from "./components/NavigationMenu.vue";

export default {
  name: "App",
  components: { NavigationMenu, CookieLaw },

  data: () => ({
    //
    // drawer: false,
    is403: false,
    cookieDialog: false,
    cookieLawOpened: true,
  }),
  computed: {
    ...mapState(["mainloading", "loading", "drawer"]),
  },
  methods: {
    ...mapActions(["getPermanentConfig", "setPermanentConfig"]),
    ...mapMutations({
      setMainLoading: "SET_MAIN_LOADING",
      toggleDrawer: "TOGGLE_DRAWER",
    }),
    cookieAccepted() {
      this.cookieLawOpened = false;
    },
    isJwtValid: function () {
      //console.log(jwt);
      // console.log("Validating jwt...");
      this.$store
        .dispatch("validateJwt")
        .then((status) => {
          if (status === "valid") {
            this.$store.dispatch("loadJwt");
          }
        })
        .catch(() => {
          // console.log(err);
          //console.log("invalid jwt found! Redirecting to cas");
          this.redirectToCas();
        });
    },
    redirectToCas: function () {
      window.location.href =
        process.env.VUE_APP_CAS_URL_FIRST_PART +
        encodeURIComponent(window.location.href);
      // console.log('redirecting to ' + process.env.VUE_APP_CAS_URL_FIRST_PART + encodeURIComponent(window.location.href));
      // https://sso.staging.unimi.it:6443/login?service=https%3A%2F%2Fpatrimoniotest.di.unimi.it%2F
    },
    validate: function (ticket, path) {
      //console.log(ticket);

      const headers = {};

      var url =
        process.env.VUE_APP_TIROCINI_API +
        "/common/obtainJwt" +
        "?ticket=" +
        ticket +
        "&service=" +
        encodeURIComponent(path);

      // We are validating ticket to TIROCINI backend
      this.$http
        .get(url, { headers })
        .then((response) => {
          //console.log(response);

          if (response.status === 200) {
            // jwt obtained we are now ready to set login true and to store the obtained jwt
            this.$store.dispatch("login", { jwt: response.data.jwt });
            this.$store.dispatch("mainloading", false);

            // If ticket present into the url redirect to home
            const myURL = URL.parse(window.location.href);
            const searchParams = new URLSearchParams(myURL.query);
            if (searchParams.get("ticket")) {
              // console.log('redirecting to ' + myURL.pathname)
              this.$router.replace(myURL.pathname);
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 404 || err.response.status === 403) {
            this.$store.dispatch("mainloading", false);
            this.is403 = true;
            setTimeout(() => {
              window.location.href = process.env.VUE_APP_CAS_URL_LOGOUT
            }, 4000);
          } else {
            console.log(err);
          }
        });
    },
  },
  beforeMount() {
    this.$store.commit('initConfig');
  },
  created: function () {
    const jwt = localStorage.getItem("tirocini_auth_token");
    const myURL = URL.parse(window.location.href);
    this.$store.dispatch("mainloading", true);
    const searchParams = new URLSearchParams(myURL.query);

    if (!(jwt === null)) {
      this.isJwtValid();
      this.$store.dispatch("mainloading", false);
    } else {
      // JWT null or invalid, check if in the url there is the cas ticket if not redirect else call backend to validate
      // console.log(this.$route);

      if (searchParams.get("ticket")) {
        // probably we were redirected here by CAS, it's now time to call our backend, pass the ticket and wait for validation
        // console.log("Ok pass the ticket to the backend for validation");
        //console.log(this.$route.query.ticket); // outputs 'CAS ticket'

        // HERE WE CALL TIROCINI BACKEND that call THOR FOR TICKET VALIDATION
        this.validate(
          searchParams.get("ticket"),
          myURL.protocol + "//" + myURL.host + myURL.pathname
        );
      } else {
        // console.log(
        //   "-> null or invalid or Expired token...go to cas to obtain a new ticket"
        // );
        this.redirectToCas();
      }
    }
  },
};
</script>
<style >
.primaryColor {
  color: #003366;
}

.v-dialog.noscroll {
  overflow-y: unset;
}

.v-dialog>.v-card>.v-card__actions {
  padding-bottom: 110px;
}

.Cookie--mytheme {
  height: 100px;
  background-color: #dddddd;
}

.Cookie--mytheme .Cookie__content {}

.Cookie--mytheme .Cookie__buttons .Cookie__button {
  background-color: #003366;
  color: #eeeeee;
  padding: 10px;
  ;
}

.Cookie--mytheme div.Cookie__button:hover {
  background-color: #1e5c9b;
}
</style>