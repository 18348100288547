import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

// Used to decode the auth jwt received
import jwt_decode from "jwt-decode";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    authJwt: null,
    loading: false,
    mainloading: false,
    userdata: {},
    role: null,
    drawer: false,
    userInitials: '',
    config: {
      admin: {
        search: '',
        numItems: 10,
      },
      advisor: {
        search: '',
        numItems: 10,
      }
    }
  },
  getters: {
  },
  mutations: {
    SET_AUTH(state, payload) {
      state.isLoggedIn = payload.isAuth
    },
    initConfig(state) {
      if (localStorage.getItem('config')) {
        state.config = JSON.parse(localStorage.getItem('config'));
      } else {
        localStorage.setItem('config', state.config);
      }
    },
    saveConfig(state, payload) {
      localStorage.setItem('config', payload);
    },
    SET_JWT(state, payload) {
      state.authJwt = payload.jwt
    },
    SET_MAIN_LOADING(state, payload) {
      state.mainloading = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_USER_DATA(state, payload) {
      state.userdata = payload
      if (payload && payload.firstname && payload.lastname) {
        state.userInitials = (payload.firstname.substring(0, 1) + payload.lastname.substring(0, 1)).toUpperCase();
      }
    },
    SET_USER_ROLE(state, payload) {
      state.role = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    TOGGLE_DRAWER(state) {
      state.drawer = !state.drawer;
    },


  },
  actions: {
    // Action used to login and out (do we have a valid jwt?)
    mainloading(context, payload) {
      context.commit('SET_MAIN_LOADING', payload);
    },
    loading(context, payload) {
      context.commit('SET_LOADING', payload);
    },
    login(context, payload) {
      context.commit('SET_AUTH', { isAuth: true });
      context.dispatch('storeJwt', { jwt: payload.jwt })

      // Extract from jwt initials and user role
      // Parse jwt content and fulfill some infoes
      const decoded = jwt_decode(payload.jwt);
      context.commit('SET_USER_DATA', decoded.user);
      context.commit('SET_USER_ROLE', decoded.user.role);
      // Get initials of the user splitting on . and taking the first char of the two elements obtained
      // let evaluatedInitials = username.split(".")[0][0] + username.split(".")[1][0];

      // console.log(evaluatedInitials + ' - ' + decodedRole);

      // context.commit('SET_USER_INITIALS',{initials: evaluatedInitials});
      // context.commit('SET_USER_ROLE', {role: decodedRole});
    },
    logout(context) {
      // console.log('Logging out!');
      context.commit('SET_DRAWER', false)
      context.commit('SET_AUTH', { isAuth: false });
      context.commit('SET_JWT', { jwt: null });
      // Delete the auth token stored
      localStorage.removeItem("tirocini_auth_token");
      context.commit('SET_USER_DATA', {});
      context.commit('SET_USER_ROLE', null);
      // console.log('jwt removed from local storage!');
      // Logout cas VUE_APP_CAS_URL_LOGOUT
      window.location.href = process.env.VUE_APP_CAS_URL_LOGOUT;

    },
    // Action to store the jwt obtained by patrimonio backend for later http request use
    storeJwt(context, payload) {
      // Store it also to the local storage (to maintain a valid session even if page reloaded)
      localStorage.setItem('tirocini_auth_token', payload.jwt)
      context.commit('SET_JWT', { jwt: payload.jwt });
      const decoded = jwt_decode(payload.jwt);
      context.commit('SET_USER_DATA', decoded);
      context.commit('SET_USER_ROLE', decoded.role);

    },
    // Action to store the jwt obtained by patrimonio backend for later http request use
    loadJwt(context) {
      // Load the jwt from memory if present
      let jwt = localStorage.getItem("tirocini_auth_token");
      if (!(jwt === null)) {
        context.commit('SET_JWT', { jwt: jwt });
      }
      const decoded = jwt_decode(jwt);
      context.commit('SET_USER_DATA', decoded.user);
      context.commit('SET_USER_ROLE', decoded.user.role);

    },
    // Action to store the jwt obtained by patrimonio backend for later http request use
    validateJwt(context) {
      return new Promise((resolve, reject) => {
        // Load the jwt from memory if present
        let jwt = localStorage.getItem("tirocini_auth_token");
        if (!(jwt === null)) {
          // Ask to backend if current found jwt is valid, if it is then use it else ask for a new jwt
          // Prepare the message
          const headers = {
            Authorization: "jwt " + jwt,
          };

          const body = {};

          //console.log(Vue);
          axios.post(process.env.VUE_APP_TIROCINI_API + "/common/jwtVerify", body, {
            headers,
          })
            .then((response) => {
              //console.log(response);
              if (response.status === 200) {
                if (response.data.status === "valid") {
                  // console.log("-> Valid token found -> autologin");
                  // Valid token found, we reload the jwt user info passing from the common onValidate method
                  context.dispatch('login', { jwt: jwt });
                  resolve("valid");
                }
              } else if (response.status === 404) {
                // console.log("The service is down. Please try again later...");
                //this.serviceDown = true;
                reject("servicedown");
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log(err.response.data);
              } else {
                console.log(err);
              }
              context.dispatch('logout');
              // console.log("JWT verification error...redirectin to CAS for new authentication!");
              reject("notvalid");
            });
        }
      })
    },

  },
  modules: {
  }
})
