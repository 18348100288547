import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      title: "Tirocini - Home",
      roles: ["studente", "admin", "segreteria", "commissione", "advisor", "none"],
      leftMenu: false,
    }
  },
  {
    path: '/tirocini',
    name: 'Tutti i tirocini',
    component: () => import('../views/AdminTirocini.vue'),
    meta: {
      title: "Tirocini - Tutti i tirocini",
      roles: ["admin", "commissione", "segreteria"],
      leftMenu: true,
    }
  },
  {
    path: '/tirocini/:id',
    name: 'Tirocinio',
    component: () => import('../views/AdminTirocinio.vue'),
    meta: {
      title: "Tirocini - Tutti i tirocini",
      roles: ["admin", "commissione", "segreteria"],
      leftMenu: false,
    }
  },
  {
    path: '/advisor/mytirocini',
    name: 'I miei tirocinanti',
    component: () => import('../views/AdvisorTirocini.vue'),
    meta: {
      title: "Tirocini - I miei tirocinanti",
      roles: ["advisor", "commissione"],
      leftMenu: true,
    }
  },
  {
    path: '/advisor/mytirocini/:id',
    name: 'Tirocini - I miei tirocinanti',
    component: () => import('../views/AdvisorTirocinio.vue'),
    meta: {
      title: "Tirocini - I miei tirocinanti",
      roles: ["advisor", "commissione"],
      leftMenu: false,
    }
  },
  {
    path: '/stats/advisor',
    name: 'Le mie statistiche',
    component: () => import('../views/AdvisorStats.vue'),
    meta: {
      title: "Tirocini - le mie statistiche",
      roles: ["advisor", "commissione"],
      leftMenu: true,
    }
  },
  {
    path: '/student/mytirocini',
    name: 'I miei tirocini - studente',
    component: () => import('../views/StudentTirocini.vue'),
    meta: {
      title: "Tirocini - I miei tirocini",
      roles: ["studente"],
      leftMenu: true,
    }
  },
  {
    path: '/student/mytirocini/add',
    name: 'Aggiungi tirocinio interno',
    component: () => import('../views/InternalTirocinio.vue'),
    meta: {
      title: "Tirocini - Nuovo tirocinio interno",
      roles: ["studente"],
      leftMenu: false,
    }
  },
  {
    path: '/student/mytirocini/addext',
    name: 'Aggiungi tirocinio esterno',
    component: () => import('../views/ExternalTirocinio.vue'),
    meta: {
      title: "Tirocini - Nuovo tirocinio esterno",
      roles: ["studente"],
      leftMenu: false,
    }
  },
  {
    path: '/admins',
    name: 'Gestori',
    component: () => import('../views/ManagersView.vue'),
    meta: {
      title: "Tirocini - Gestori",
      roles: ["admin", "segreteria", "commissione"],
      parent: 'settings',
      leftMenu: true,
    }
  },
  {
    path: '/courses',
    name: 'Corsi di Laurea',
    component: () => import('../views/CoursesView.vue'),
    meta: {
      title: "Tirocini - Corsi di Laurea",
      roles: ["admin", "segreteria", "commissione"],
      parent: 'settings',
      leftMenu: true,
    }
  },
  {
    path: '/stats',
    name: 'Statistiche',
    component: () => import('../views/AdminStats.vue'),
    meta: {
      title: "Tirocini - statistiche",
      roles: ["admin", "commissione", "segreteria"],
      parent: 'settings',
      leftMenu: true,
    }
  },
  {
    path: '*',
    name: 'Pagina non trovata',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      title: "Tirocini - Pagina non trovata",
      roles: ["studente", "admin", "segreteria", "commissione"],
      leftMenu: false,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  // If user role is not permitted redirect to /
  if (store.state && store.state.role && !to.meta.roles.includes(store.state.role)) {
    // router.replace('/');
    window.location.href = '/';
  }

  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = "Gestione Tirocini"
  }

  next();
})

export default router
