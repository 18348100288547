<template>
  <v-container>
    <v-card v-if="role === 'guest'" elevation="8">
      <v-card-title>Accesso non consentito</v-card-title>
      <v-card-text>
        L'accesso è riservato agli studenti dei corsi di laurea di area informatica ed ai docenti relatori di tesi di area informatica.
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HomeView",
  data: () => {
    return {
      logoutURL: process.env.VUE_APP_CAS_URL_LOGOUT,
    };
  },
  computed: {
    ...mapState(["mainloading", "loading", "userdata", "role"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  watch: {
    role(newval) {
      if(newval === 'admin' || newval === 'commissione' || newval === 'segreteria') {
      window.location.href = '/tirocini';
    } else if(newval === 'studente') {
      // this.$router.replace('/student/mytirocini')
      window.location.href = '/student/mytirocini';
    } else if(newval === 'advisor') {
      // this.$router.replace('/advisor/mytirocini')
      window.location.href = '/advisor/mytirocini';
    } else {
      // this.$router.replace('/tirocini')
      console.log(newval)
      // window.location.href = '/tirocini';
    }

    },
  },
  components: {},
  created() {
    if(this.role && (this.role === 'admin' || this.role === 'commissione' || this.role === 'segreteria')) {
      window.location.href = '/tirocini';
    } else if(this.role && this.role === 'studente') {
      // this.$router.replace('/student/mytirocini')
      window.location.href = '/student/mytirocini';
    } else if(this.role && this.role === 'advisor') {
      // this.$router.replace('/advisor/mytirocini')
      window.location.href = '/advisor/mytirocini';
    } else {
      // this.$router.replace('/tirocini')
      console.log(this.role)
      // window.location.href = '/tirocini';
    }
  },
};
</script>
